<template>
  <div>
    <Header/>
    <b-container style="padding-top:70px;">
      <b-row>
        <b-col>
          <h1>管理項目ダウンロード</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item>
                <b-button variant="primary" @click="getCsvExportData('newEstate')">新規物件登録から撮影までの日数</b-button>
                <div class="mt-2">カメラマンが撮影依頼を受けてから何日で撮影したか取得表示したい項目</div>
              </b-list-group-item>
              <b-list-group-item>
                <b-button variant="primary" @click="getCsvExportData('elapsedDay')">前回撮影からの日数</b-button>
                <div class="mt-2">各物件の撮影チケットの前回からの撮影日数31日を超過したものを表示</div>
              </b-list-group-item>
              <b-list-group-item>
                <b-button variant="primary" @click="getCsvExportData('property')">各工程を漏れなく撮影しているか</b-button>
                <div class="mt-2">6工程を全て撮影しているか 各物件の撮影工程が揃っているか 初回撮影工程以降、抜けているものだけを表示</div>
              </b-list-group-item>
              <b-list-group-item>
                <b-button variant="primary" @click="getCsvExportData('shootingTime')">その日の撮影時間_最初の撮影から終わりの撮影までの時間</b-button>
                <div class="mt-2">担当者の当日の撮影時間の比較</div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import aws from 'aws-sdk'
import fileSaver from 'file-saver'
import { Apimixin } from '../../mixins/api'

export default {
  title: '管理項目ダウンロード',
  components: {
    Header
  },
  mixins:[Apimixin],
  data() {
    return {
      s3:'',
      s3Bucket:'',
      csvData:''
    }
  },
  created() {
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  methods: {
    //csv出力
    getCsvExportData(type) {
      this.csvData = ''
      this.getCsvExport(type)
    },
    //s3のcsvデータダウンロード
    async downloadCsvExport(csvData) {
      try {
        await this.s3.getObject({
          Bucket: this.s3Bucket,
          Key: csvData
        }).promise()
        .then(response => {
          console.log(response)
          let blob = new Blob([response.Body], {type: 'text/csv'});
          fileSaver.saveAs(blob, csvData);
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`CSVダウンロードエラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
      this.deleteCsvExportObject(csvData)
    },
    //s3のcsvデータ削除
    async deleteCsvExportObject(csvData) {
      try {
        await this.s3.deleteObject({
          Bucket: this.s3Bucket,
          Key: csvData
        }).promise()
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`CSV削除エラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
    },
  },
}
</script>
